import { fetchAppId, fetchCallback } from '@/service';

const lang = window.navigator.language;

export function apiAuth(cb: Function): void {
  console.info('..hello in auth');
  if (!h5sdk) {
    console.info('invalid h5sdk');
    // alert('please open in feishu');
    console.error('please open in feishu');
    return;
  }

  // 通过服务端的Route: get_appid获取app_id
  // 服务端Route: get_appid的具体内容请参阅服务端模块server.py的get_appid()函数
  // 为了安全，app_id不应对外泄露，尤其不应在前端明文书写，因此此处从服务端获取
  fetchAppId().then(res1 => {
    console.info('get appid succeed: ', res1.appid);
    // 通过error接口处理API验证失败后的回调
    h5sdk.error(err => {
      // throw('h5sdk error:', JSON.stringify(err));
      console.error('h5sdk error:', JSON.stringify(err));
    });
    // 通过ready接口确认环境准备就绪后才能调用API
    h5sdk.ready(() => {
      console.info('h5sdk.ready');
      console.info('url:', window.location.href);
      // 调用 JS API tt.requestAuthCode 获取 authorization code
      console.info('window.tt', window.tt);
      console.info('tt', tt);
      if (!tt || typeof tt === undefined) return;
      tt.getUserInfo({
        withCredentials: true,
        success(res) {
          console.info(JSON.stringify(res));
        },
        fail(res) {
          console.info(`getUserInfo fail: ${JSON.stringify(res)}`);
        },
      });

      tt.requestAuthCode({
        appId: res1.appid,
        // 获取成功后的回调
        success(res) {
          console.info('getAuthCode succeed res', res);
          // authorization code 存储在 res.code
          // 此处通过fetch把code传递给接入方服务端Route: callback，并获得user_info
          // 服务端Route: callback的具体内容请参阅服务端模块server.py的callback()函数
          fetchCallback({ code: res.code }).then(res2 => {
            console.info('getUserInfo succeed', res2);
            if (typeof cb === 'function') {
              cb(res2, lang);
            }
          }).catch(function(e) { console.error(e); });
        },
        // 获取失败后的回调
        fail(err) {
          console.info('getAuthCode failed, err:', JSON.stringify(err));
        },
      });
    });
  }).catch(function(e) { // 从服务端获取app_id失败后的处理
    console.error(e);
  });
}
