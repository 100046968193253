import axios from '@/utils/axios';
import { serviceHost } from '@/environment';

export interface IUserInfo {
  avatar?: string;
  avatar_big?: string,
  avatar_middle?: string,
  avatar_thumb?: string,
  avatar_url?: string,
  email?: string,
  employee_no?: string,
  en_name?: string,
  enterprise_email?: string,
  mobile?: string,
  name?: string,
  open_id?: string,
  tenant_key?: string,
  union_id?: string,
  user_id?: string,
}

interface ILoginResponse {
  is_login: boolean;
  code?: number;
  data?: any;
}
export const checkIfLogin = (): Promise<ILoginResponse> => {
  return axios
    .get('/lark/auth/is_login')
    .then((res) => res.data);
};

interface IAppIdResponse {
  appid: string;
}
export const fetchAppId = (): Promise<IAppIdResponse> => {
  return axios
    .get('/lark/auth/app_id')
    .then((res) => res.data);
};

interface IConfigParametersRequest {
  url: string;
}
interface IConfigParametersResponse {
  code: number;
  data: {
    appid: string;
    timestamp: number; // Date timestamp
    noncestr: string;
    signature: string;
  };
  msg: string;
}
export const fetchConfigParameters = (params: IConfigParametersRequest): Promise<IConfigParametersResponse> => {
  return axios
    .get('/lark/auth/config_param', { params })
    .then((res) => res.data);
};

interface ICallbackRequest {
  code: string;
}
type ICallbackResponse = IUserInfo
export const fetchCallback = (params: ICallbackRequest): Promise<ICallbackResponse> => {
  return axios
    .get('/lark/auth/callback', { params })
    .then((res) => res.data);
};
