import * as Sentry from '@sentry/react';
import { isLocal } from '@/config';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export const sendPV = (): void => {
  const location = useLocation();
  useEffect(() => {
    try {
      // 这里写你的PV埋点代码
      if (!isLocal) {
        Sentry.captureMessage(`PageView: ${location.pathname}`);
        Sentry.captureMessage('PageView: website');
      }
    } catch (err) {
      console.error('埋点报错：', err);
    }
  }, [location]); // 注意这里依赖于 location，当 location 变化时重新执行
};
