export enum ENUM_NODE_ENV {
  development = 'development',
  feature = 'feature', // 目前没有用到这个环境
  production = 'production',
}

// 代表线上，非 dev（test）环境
export const isPro = process.env.REACT_APP_ENVIRONMENT === ENUM_NODE_ENV.production;
// 代表部署的 dev 环境
export const isDev = process.env.REACT_APP_ENVIRONMENT === ENUM_NODE_ENV.development;
// 代表本地。
export const isLocal = process.env.NODE_ENV === ENUM_NODE_ENV.development;
