import { MessageFormatElement, useIntl } from 'react-intl';

// @deprecated 此方法废弃，会造成大量的报错，使用 useTrans 替代。
export const $t = (value1: string, value2?: string): string | MessageFormatElement[] => {
  const locale = useIntl();
  if (value2 === undefined) {
    value2 = value1;
  }
  if (locale.messages[value1] !== undefined) {
    return locale.messages[value1];
  }
  return value2;
};

type TUseTransFunc = (value1: string, value2?: string) => string | MessageFormatElement[];
export const useTrans = (): TUseTransFunc => {
  const locale = useIntl();
  return (value1: string, value2?: string) => {
    if (value2 === undefined) {
      value2 = value1;
    }
    if (locale.messages[value1] !== undefined) {
      return locale.messages[value1];
    }
    return value2;
  };
};
