export const getLang = (): string => {
  const langInStore = window.sessionStorage.getItem('lang');
  const langInNav = navigator.language;
  const defaultLang = 'zh-CN';
  return langInStore || defaultLang;
};

export const storeLang = (lang: string): void => {
  window.sessionStorage.setItem('lang', lang);
};
