// 创建一个文件，暂且命名为 context.tsx，导出 createContext() 的返回值
import React, { createContext, useState } from 'react';

import { apiAuth } from './utils/feishu-api-auth';
import { isDev } from '@/config/index';
import { checkIfLogin, fetchAppId } from './service';
import type { IUserInfo } from './service';
import { apiJsApi } from './utils/feishu-api-jsapi';
import { message } from 'antd';

// 登录链接
const loginUrl = '/';

interface IContext {
  userInfo?: IUserInfo;
  initialized?: boolean;
}

export const useFeishu = (): IContext => {
  const feishuContext = React.useContext(FeishuContext);
  return feishuContext;
};

export const FeishuContext = createContext<IContext>({
  userInfo: {},
  initialized: false,
});

export const FeishuProvider: React.FC = (props) => {
  const { children } = props;
  const [userInfo, setUserInfo] = useState<IUserInfo>();
  const [initialized, setInitialized] = useState<boolean>(false);
  const handleInfo = (isInitialized: boolean, info: any) => {
    setInitialized(isInitialized);
    setUserInfo(info);
  };
  React.useEffect(() => {
    console.info('main isDev', isDev, location.pathname);
    const isTrue = true;
    if (isTrue) {
      checkIfLogin().then(res => {
        const { code, data = {} } = res || {};
        console.info('checkInfLogin', res, 'location.href', location.href);
        if (code !== 0) {
          // 接口返回 html 的情况
          message.error('登录失败！');
          handleInfo(true, {});
          return;
        }
        const { user_info } = data;
        /**
         * 刷新页面后的判断，
         * 若登录了，则直接获取用户信息。
         * 若未登录，则走登录流程。
         */
        if (data?.is_login) {
          handleInfo(true, user_info);
          return;
        } else {
          console.info('测试当前的 pathname 是什么 location.pathname !== loginUrl',
            location.pathname !== loginUrl, 'location.pathname', location.pathname);
          if (location.pathname !== loginUrl) {
            const { pathname, search } = location;
            console.info('Current location is', location, JSON.stringify(location));
            const oldUrl = `${pathname}`;
            console.info('oldUrl is', oldUrl);
            location.href = `${loginUrl}?redirect=${encodeURIComponent(oldUrl)}&queryStr=${encodeURIComponent(search)}`;
          }
        }
        if (!data?.is_login || isTrue) {
          const h5sdk = window.h5sdk;
          if (!h5sdk) {
            // TODO: Testing
            handleInfo(true, { name: 'Name Admin' });
            // setTimeout(() => {
            //   setInitialized(true);
            // }, 10000);
            /**
             * 飞书浏览器网页登录：https://open.feishu.cn/document/uYjL24iN/ukTO4UjL5kDO14SO5gTN#9f534005
             */
            fetchAppId().then(res1 => {
              // axios
              //   .post('/open-apis/authen/v1/access_token')
              //   .then((res) => {
              //     console.info('...post...res.data', res.data);
              //   });
              const APPID = res1.appid;
              // const APPID = 'cli_a3f6f05923b9d00b';
              const REDIRECT_URI = encodeURIComponent(location.origin);
              const STATE = 'RANDOMSTATE';
              const loginPath = 'https://open.feishu.cn/open-apis/authen/v1/index';
              const url = `${loginPath}?redirect_uri=${REDIRECT_URI}&app_id=${APPID}&state=${STATE}`;
              console.info('重定向 url', url);
              // location.href = url;
            });
          } else {
            apiAuth((res, lang) => {
              if (!data?.is_login) {
                window.location.reload();
                return;
              }
              handleInfo(true, res);
              console.info('res', res);
              console.info('lang', lang);
            });
          }
        }
      }).catch(err => {
        // 接口 502 的情况。
        message.error('登录失败！Error is:', err);
        console.error('Error is', err);
        handleInfo(true, {});
      });
    }
  }, []);

  return (
    <FeishuContext.Provider
      value={{
        userInfo,
        initialized,
      }}
    >
      {children}
    </FeishuContext.Provider>
  );
};
