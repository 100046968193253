import { LangConf } from './index';
import menu from './zh-CN/menu';
const conf: LangConf = {
  locale: 'zh',
  desc: '简体中文',
  hello: '你好',
  logout: '注销',
  'form.msg': '请输入正确内容',
  'form.select_msg': '请选择或新建感知类型',
  'form.add': '添加',
  'page.total': '共{total}项',
  ...menu,
};
export default conf;
