import menu from './en-US/menu';
import { LangConf } from './index';
const conf: LangConf = {
  locale: 'en',
  desc: 'English',
  hello: 'hello',
  logout: 'Logout',
  'form.msg': 'Please enter the correct content',
  'form.add': 'Add Type',
  'form.select_msg': 'Please select or create a new perception type',
  'page.total': 'Total {total} items',
  ...menu,
};
export default conf;
