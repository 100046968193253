export default {
  'header.description': 'Travel',
  'header.PROJECT_NAME': 'Travel',
  home: 'home',
  'login.logout': 'Logout',

  // 通用
  Delete: 'Delete',
  Edit: 'Edit',
  'No Permission': 'No Permission',
  'Server Error': 'Server Error',
};
