import React from 'react';
import { Spin } from 'antd';

const Loading = (): JSX.Element => (
  <div
    style={{
      height: 200,
      lineHeight: '200px',
      textAlign: 'center',
    }}
  >
    <Spin size="large" />
  </div>
);
export default Loading;
