import { Suspense } from 'react';
import {
  // BrowserRouter as Router,
  Link,
  Switch,
  Route,
  // useHistory,
} from 'react-router-dom';
import Loading from '../loading';
import { Layout, Breadcrumb } from 'antd';
import { FormattedMessage } from 'react-intl';
import { routes } from './../../config/routes';
import { kebabCase } from 'lodash';

const { Content } = Layout;

// TODO: 书写自动化测试代码，验证逻辑。
const getRoutes = () => (
  <>
    {routes.map((route) => (
      <RouteWithSubRoutes {...route} key={route.key} />
    ))}
  </>
);

const getBreadCrumb = (route) => {
  const meta = route.meta || [];
  const className = `${kebabCase(route.name)}-breadcrumb breadcrumb-area`;
  return (
    <Breadcrumb className={className}>
      <Breadcrumb.Item key={'header.PROJECT_NAME'}>
        <FormattedMessage id={'header.PROJECT_NAME'} />
      </Breadcrumb.Item>

      {meta.map((item) => (
        <Breadcrumb.Item key={item}>
          <FormattedMessage id={item} defaultMessage={item} />
        </Breadcrumb.Item>
      ))}

      <Breadcrumb.Item key={route.key}>
        <FormattedMessage id={route.name} defaultMessage={route.name} />
      </Breadcrumb.Item>
    </Breadcrumb>
  );
};

// A special wrapper for <Route> that knows how to
// handle "sub"-routes by passing them in a `routes`
// prop to the component it renders.
function RouteWithSubRoutes(route) {
  if (route.children && route.children.length > 0) {
    return route.children.map((item) => RouteWithSubRoutes(item));
  }
  const path = route.path;
  const className = `${kebabCase(route.name)}-page`;
  return (
    <Route path={path} key={path} exact>
      {/* {getBreadCrumb(route)} */}
      <Content className={`site-layout-background ${className}`}>
        <Suspense fallback={<Loading />}>
          {route.component && <route.component />}
        </Suspense>
      </Content>
    </Route>
  );
}

export default function RenderContent() {
  // const his = useHistory();
  // const url = his.location.pathname;
  // const reg = /\d+/g;

  // const carid = reg.exec(url)?.[0];
  return <Switch>{getRoutes()}</Switch>;
}
