/* eslint-disable prefer-promise-reject-errors */
import axios from 'axios';
import { message } from 'antd';
import { isLocal } from '@/config';
axios.interceptors.request.use(
  (config) => {
    if (localStorage.token) {
      config.headers.Authorization = 'Bearer ' + localStorage.token;
    }
    if (window.sessionStorage.getItem('lang') === 'en-US') {
      config.headers['Accept-Language'] = 'en-US,en;q=0.9,en-US;q=0.8,en;q=0.7';
    } else {
      config.headers['Accept-Language'] = 'zh-CN,zh;q=0.9,en-US;q=0.8,en;q=0.7';
    }
    const { url = '' } = config || {};
    // 全局 URL 补 debug=1
    if (isLocal) {
      const matchStatus = url?.match(/debug=1/);
      if (matchStatus == null) {
        config.url = `${url}?debug=1`;
      }

      // const { method } = config || {};
      // 每个 get 请求加上 debug = 1;
      // if (method === 'get' || method === 'GET') {
      //   if (!config.params) {
      //     config.params = {};
      //   }
      //   config.params.debug = 1;
      // }
      // post 请求，因为不是补到 data 里，还是补 url 上。
    }
    return config;
  },
  (err) => {
    return Promise.reject(err);
  },
);
axios.interceptors.response.use(
  (config) => {
    return config;
  },
  (e) => {
    if (e.response?.status === 401) {
      // TODO: 401 是否需要重新刷新页面。
      // window.location.reload();
      return Promise.reject('No Permission');
    }
    if (e.response?.status === 403) {
      message.error('No Permission');
      return Promise.reject('No Permission');
    }
    if (e.response?.status >= 500) {
      return Promise.reject('Server Error');
    }
    return Promise.reject(e);
  },
);

export default axios;
