export default {
  'header.description': '差旅中台',
  'header.PROJECT_NAME': '差旅中台',
  'home':'home',
  'login.logout': '退出登陆',

  // 通用
  Delete: '删除',
  Edit: '编辑',
  'No Permission': '没有权限',
  'Server Error': '服务器错误',
};
